import React from 'react';
import className from 'classnames';
import { Link } from 'gatsby';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ensureGtag from '../helpers/ensureGtag';
import SEO from '../components/SEO';
import { CampaignPage } from '../imageURLs';
import TwoSquareBlock from '../components/TwoSquareBlock';
import { UiButton } from '../components/CTAButton/CTAButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Stars from '../images/stars.svg';
import Wizard from '../components/Wizard/Wizard';
import Image from '../components/Hero/HeroImage';
import { wizardConfig } from '../constants';
import { withQuoteWizard } from '../components/withQuoteWizard';

const Transition = props => <Slide direction="up" {...props} />;

const styles = theme =>
  createStyles({
    contentWrap: {
      padding: 0,
      marginBottom: 50,

      [theme.breakpoints.up(500)]: {
        padding: 40,
      },
    },
    heroImageWrap: {
      height: '100vw',
      position: 'relative',
      maxHeight: 500,
      overflow: 'hidden',
      marginBottom: 30,
      [theme.breakpoints.up(500)]: {
        marginBottom: 0,
      },
      [theme.breakpoints.up('sm')]: {
        maxHeight: 500,
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: 500,
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: 600,
      },
    },
    heroImage: {
      maxHeight: 600,
      height: 600,
    },
    starImg: {
      width: '100%',
      height: 'auto',
      display: 'block',
      padding: '0 20px',
    },
    ctaWrap: {
      height: '100vw',
      maxHeight: 400,
      marginBottom: 30,
      [theme.breakpoints.up('md')]: {
        maxHeight: 500,
      },
      [theme.breakpoints.up('lg')]: {
        height: 600,
        maxHeight: 'none',
      },
    },
    ctaStatement: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(32),
      maxWidth: 800,
      padding: '0 10px',
      marginBottom: 36,

      [theme.breakpoints.up('sm')]: {
        marginBottom: 40,
        fontSize: theme.typography.pxToRem(50),
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 24px',
        fontSize: theme.typography.pxToRem(80),
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1100,
        fontSize: theme.typography.pxToRem(100),
      },
    },
    ctaQuestion: {
      fontSize: theme.typography.pxToRem(20),
      textTransform: 'none',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      lineHeight: 1.5,
      padding: '10px 25px 13px',
      fontWeight: 300,
      borderRadius: 32,
      textAlign: 'center',
      minWidth: 250,
      [theme.breakpoints.up('sm')]: {
        borderRadius: 42,
        fontSize: theme.typography.pxToRem(30),
        padding: '15px 30px 18px',
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: 50,
        fontSize: theme.typography.pxToRem(40),
        padding: '18px 38px 21px',
      },
      [theme.breakpoints.up('lg')]: {
        borderRadius: 70,
        minWidth: 630,
        fontSize: theme.typography.pxToRem(50),
        padding: '22px 45px 30px',
      },
    },
    pinkCtaWrap: {
      backgroundColor: '#ECBAAB',
    },
    pinkCtaStatement: {},
    pinkCtaQuestion: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    greenCtaWrap: {
      backgroundColor: '#5e8545',
    },
    greenCtaQuestion: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    blueCtaWrap: {
      backgroundColor: '#267695',
    },
    blueCtaQuestion: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    brownCtaWrap: {
      backgroundColor: '#ceaa72',
    },
    brownCtaQuestion: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    awardText: {
      marginTop: 20,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    awardTextSecondary: {
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
      },
    },
    linkBtn: {
      textDecoration: 'none',
    },
  });

class Campaign extends React.Component {
  state = {
    isLawnWizardOpen: false,
  };

  toggleLawnWizard = (stepNumber, totalStep = '') => {
    this.setState(prevState => {
      const isOpen = !prevState.isLawnWizardOpen;

      if (!isOpen) {
        // on close only
        totalStep = totalStep && `/${totalStep}`;
        ensureGtag('event', `wizard_closed_on_${stepNumber}${totalStep}_step`, {
          event_category: 'Wizard_2020_Campaign_1',
        });
      }

      return {
        isLawnWizardOpen: isOpen,
      };
    });
  };

  render() {
    const { classes, children, toggleQuoteComp } = this.props;
    const { isLawnWizardOpen } = this.state;

    return (
      <React.Fragment>
        <SEO
          title="Live your best life in 2020 - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
            `Live your best life`,
          ]}
        />
        <Grid container alignItems="stretch" className={classes.heroImageWrap}>
          <Grid container>
            <Image
              relativePath={CampaignPage.hero}
              className={classes.heroImage}
            />
          </Grid>
        </Grid>

        <Grid container direction="column" className={classes.contentWrap}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            className={className(classes.ctaWrap, classes.pinkCtaWrap)}
          >
            <Typography
              variant="h2"
              color="primary"
              className={className(
                classes.ctaStatement,
                classes.pinkCtaStatement
              )}
            >
              Live your best life with a new lawn.
            </Typography>

            <Typography
              className={className(
                classes.ctaQuestion,
                classes.pinkCtaQuestion
              )}
            >
              How will you live yours?
            </Typography>
          </Grid>

          <TwoSquareBlock
            squareOneImage={CampaignPage.feelGoodSoccer}
            squareTwoTitle="Play"
            squareTwoSubtext="like you're a kid again."
            squareTwoImageOne={CampaignPage.soccer}
            squareTwoImageTwo={CampaignPage.dog}
            squareTwoImageThree={CampaignPage.cricket}
          ></TwoSquareBlock>

          <TwoSquareBlock
            squareOneImage={CampaignPage.feelGoodPlanks}
            squareTwoTitle="Achieve"
            squareTwoSubtext="like there's no tomorrow."
            squareTwoImageOne={CampaignPage.shoes}
            squareTwoImageTwo={CampaignPage.skipping}
            squareTwoImageThree={CampaignPage.kettlebell}
            inverted
          ></TwoSquareBlock>

          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            className={className(classes.ctaWrap, classes.greenCtaWrap)}
          >
            <Typography
              variant="h2"
              color="secondary"
              className={className(
                classes.ctaStatement,
                classes.greenCtaStatement
              )}
            >
              Find your best lawn in 10 seconds.
            </Typography>

            <UiButton
              variant="contained"
              color="primary"
              btnType="wide"
              className={className(classes.ctaQuestion)}
              onClick={this.toggleLawnWizard}
            >
              Take the quiz
            </UiButton>
          </Grid>

          <TwoSquareBlock
            squareOneImage={CampaignPage.feelGoodAerial}
            squareTwoTitle="Celebrate"
            squareTwoSubtext="life's simple pleasures."
            squareTwoImageOne={CampaignPage.champagne}
            squareTwoImageTwo={CampaignPage.tongs}
            squareTwoImageThree={CampaignPage.cricket}
          ></TwoSquareBlock>

          <TwoSquareBlock
            squareOneImage={CampaignPage.feelGoodBubbles}
            squareTwoTitle="Live"
            squareTwoSubtext="in the moment."
            squareTwoImageOne={CampaignPage.heart}
            squareTwoImageTwo={CampaignPage.dog}
            squareTwoImageThree={CampaignPage.laugh}
            inverted
          ></TwoSquareBlock>

          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            className={className(classes.ctaWrap, classes.blueCtaWrap)}
          >
            <Typography
              variant="h2"
              color="secondary"
              className={className(
                classes.ctaStatement,
                classes.blueCtaStatement
              )}
            >
              Let us help you choose what's best
            </Typography>

            <Typography
              className={className(
                classes.ctaQuestion,
                classes.blueCtaQuestion
              )}
              component="a"
              href="tel:0754967393"
            >
              Call 5496 7393
            </Typography>
          </Grid>

          <Grid
            container
            alignItems="stretch"
            className={classes.heroImageWrap}
          >
            <Grid container>
              <Image
                relativePath={CampaignPage.hero2}
                className={classes.heroImage}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            className={className(classes.ctaWrap, classes.brownCtaWrap)}
          >
            <Typography
              variant="h2"
              color="primary"
              className={className(
                classes.ctaStatement,
                classes.brownCtaStatement
              )}
            >
              The highest rated lawns on Google
            </Typography>

            <Link to="/testimonials" className={classes.linkBtn}>
              <Typography
                className={className(
                  classes.ctaQuestion,
                  classes.brownCtaQuestion
                )}
                component="span"
              >
                Read real reviews
              </Typography>
            </Link>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <Grid container>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <img className={classes.starImg} alt="" src={Stars} />
              </Grid>
            </Grid>

            <Grid container justify="center" className={classes.awardText}>
              <Typography variant="h2" color="primary" inline>
                <strong>Twin View Turf</strong>
              </Typography>
              <Typography
                variant="h2"
                color="primary"
                inline
                className={classes.awardTextSecondary}
              >
                <Hidden smDown>
                  <span style={{ display: 'inline-block', padding: '0 10px' }}>
                    {' '}
                    |{' '}
                  </span>
                </Hidden>
                Australia's Best Lawns
              </Typography>
            </Grid>

            <Grid
              container
              justify="center"
              spacing={24}
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                <Link to="/turf-nullarbor-couch" className={classes.linkBtn}>
                  <UiButton size="small" btnType="hollow">
                    Nullarbor Couch
                  </UiButton>
                </Link>
              </Grid>

              <Grid item>
                <Link to="/turf-tiftuf" className={classes.linkBtn}>
                  <UiButton size="small" btnType="hollow">
                    TifTuf
                  </UiButton>
                </Link>
              </Grid>

              <Grid item>
                <Link
                  to="/turf-sir-walter-dna-certified"
                  className={classes.linkBtn}
                >
                  <UiButton size="small" btnType="hollow">
                    Sir Walter DNA Certified
                  </UiButton>
                </Link>
              </Grid>

              <Grid item>
                <Link to="/turf-sir-grange" className={classes.linkBtn}>
                  <UiButton size="small" btnType="hollow">
                    Sir Grange
                  </UiButton>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          fullScreen
          open={isLawnWizardOpen}
          TransitionComponent={Transition}
          PaperProps={{
            style: { backgroundColor: '#e9e9e9' },
          }}
        >
          <Wizard
            header="Lawn Wizard"
            questions={wizardConfig.steps}
            results={wizardConfig.results}
            closeFn={this.toggleLawnWizard}
            openQuote={toggleQuoteComp}
          />
        </Dialog>
        {children}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(Campaign)
);
