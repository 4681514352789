import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Image from '../components/Image';

const minHeightBox = "100vw";

const styles = theme =>
  createStyles({
    root: {
      marginBottom: 30,
    },
    title: {
      paddingBottom: 10,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(32),

      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(50),
      },

      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(80),
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.typography.pxToRem(100),
      },
    },
    subtitle: {
      paddingBottom: 40,
      fontWeight: 200,
      fontSize: theme.typography.pxToRem(32),

      [theme.breakpoints.up('md')]: {
        paddingBottom: 60,
        fontSize: theme.typography.pxToRem(40),
      },
    },
    imageBlock: {
      order: 1,
      width: '100%',
      minHeight: minHeightBox,

      [theme.breakpoints.up('md')]: {
        minHeight: '50%',
        width: 'calc(50% - 15px)',
        height: '50vw',
      },
    },
    imageBlockImage: {
      height: '100%',
      width: '100%',
    },
    contentBlock: {
      order: 0,
      backgroundColor: '#F7F7F7',
      width: '100%',
      minHeight: minHeightBox,
      marginBottom: 30,

      [theme.breakpoints.up('md')]: {
        minHeight: '50%',
        order: 2,
        width: 'calc(50% - 15px)',
        height: '50vw',
        marginBottom: 0,
      },
    },
    invertedContentBlock: {
      order: 0,
    },
    invertedText: {
      backgroundColor: 'lightgrey',
      paddingLeft: 0,
    },
    normalText: {
      backgroundClip: 'content-box',
      backgroundColor: 'lightgrey',
      paddingRight: 0,
    },
  });

const ContentBlockBase = ({
  classes,
  title,
  subtitle,
  imageOne,
  imageTwo,
  imageThree,
  className = '',
}) => (
  <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    className={classNames(classes.contentBlock, { [className]: !!className })}
  >
    <Grid item>
      <Typography className={classes.title} variant="h2">
        {title}
      </Typography>
    </Grid>
    <Grid item>
      <Typography className={classNames(classes.subtitle)} variant="h2">
        {subtitle}
      </Typography>
    </Grid>
    <Grid container justify="space-evenly" alignItems="center">
      <Grid item xs={2}>
        <Image
          relativePath={imageOne}
          imgStyle={{
            width: '100%',
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Image
          relativePath={imageTwo}
          imgStyle={{
            width: '100%',
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Image
          relativePath={imageThree}
          imgStyle={{
            width: '100%',
          }}
        />
      </Grid>
    </Grid>
  </Grid>
);

const ContentBlock = withStyles(styles, { withTheme: true })(ContentBlockBase);

const TwoSquareBlock = ({
  classes,
  squareOneImage,
  squareTwoTitle,
  squareTwoSubtext,
  squareTwoImageOne,
  squareTwoImageTwo,
  squareTwoImageThree,
  inverted = false,
}) => (
  <Grid
    container
    justify="space-between"
    item
    className={classes.root}
    alignItems="stretch"
  >
    <Grid
      container
      alignItems="stretch"
      className={classes.imageBlock}
    >
      <Image
        className={classes.imageBlockImage}
        relativePath={squareOneImage}
      />
    </Grid>
    <ContentBlock
      className={classNames({ [classes.invertedContentBlock]: inverted })}
      title={squareTwoTitle}
      subtitle={squareTwoSubtext}
      imageOne={squareTwoImageOne}
      imageTwo={squareTwoImageTwo}
      imageThree={squareTwoImageThree}
    />
  </Grid>
);

export default withStyles(styles, { withTheme: true })(TwoSquareBlock);
