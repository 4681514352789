import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ensureGtag from '../../helpers/ensureGtag';

import Button from './Button';

import CloseButton from '../Quote/CloseButton';

const styles = theme =>
  createStyles({
    root: {
      backgroundColor: '#1BB25F',
      padding: '50px 30px',
      textAlign: 'center',
      position: 'relative',
    },
    header: {
      color: '#fff',
    },
    stepWrap: {
      color: '#fff',
      borderBottom: '1px solid #fff',
      padding: '0 40px 30px',
      marginBottom: 30,
    },
    stepContentWrap: {
      maxWidth: 600,
      alignSelf: 'center',
      maxHeight: 800,
    },
    questionWrap: {
      color: '#fff',
      marginBottom: 20,
    },
    answerWrap: {
    },
    closeLink: {
      textDecoration: 'none',
    },
    closeLinkText: {
      color: '#fff',
      fontSize: '1.5rem',
      textAlign: 'center',
      [theme.breakpoints.up(400)]: {
        fontSize: `${theme.typography.enlargePercent(1.5)}rem`,
      },
    }
  });

const WizardStep = ({
  classes,
  header,
  currentStep,
  totalStep,
  question,
  answerA,
  answerB,
  onSelect,
  closeFn
}) => (
  <Grid
    item
    container
    direction="column"
    justify="center"
    className={classes.root}
  >
    <CloseButton closeFn={closeFn} />
    <Grid item container direction="column"
      className={classes.stepContentWrap}
    >
      <Grid item container direction="column" alignContent="center">
        <Typography variant="h3" component="h3" className={classes.header}>
          {header}
        </Typography>
        <Typography variant="h5" component="h5" className={classes.stepWrap}>
          {`Question ${currentStep + 1} of ${totalStep}`}
        </Typography>
        <Typography variant="h3" component="h3" className={classes.questionWrap}>
          {question}
        </Typography>
      </Grid>
      <Grid item className={classes.answerWrap} container justify="center">
        <Button answer={answerA} onSelect={onSelect} />
        <Button answer={answerB} onSelect={onSelect} />
      </Grid>
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(
  class WStep extends React.Component {
    componentDidMount() {
      const { currentStep, totalStep } = this.props;

      ensureGtag('event', `wizard_step_${currentStep + 1}/${totalStep}_seen`, {
        event_category: 'Wizard',
      });
    }

    componentDidUpdate(prevProps) {
      const { currentStep, totalStep } = this.props;
       if (prevProps.currentStep !== currentStep) {
        ensureGtag('event', `wizard_step_${currentStep + 1}/${totalStep}_seen`, {
          event_category: 'Wizard',
        });
      }
    }

    render () {
       return <WizardStep {...this.props} />;
    }
  }
);
