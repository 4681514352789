import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import ensureGtag from '../../helpers/ensureGtag';

import WizardStep from './WizardStep';
import Result from './Result';

const styles = theme => createStyles({});

class Wizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: [],
      currentStep: 0,
    };

    this.chooseAnswer = this.chooseAnswer.bind(this);
  }

  componentDidMount() {
    ensureGtag('event', 'wizard_open', {
      event_category: 'Wizard',
    });
  }

  chooseAnswer(answer) {
    this.setState(prevState => ({
      answers: [...prevState.answers, answer],
      currentStep: prevState.currentStep + 1,
    }));
  }

  getQuote = () => {
    ensureGtag('event', 'quote_open', {
      event_category: 'Wizard',
      event_callback: () => this.props.openQuote(),
    });
  };

  getResultsByAnswers = () => {
    return find(this.props.results, resultOption => {
      return isEqual(resultOption.choices, this.state.answers);
    });
  };

  render() {
    const { header, questions, closeFn } = this.props;
    const { currentStep } = this.state;
    const totalStep = questions.length;
    const currentQuestion = questions[currentStep];

    if (currentStep === totalStep) {
      const result = this.getResultsByAnswers();
      return (
        <Result
          result={result}
          getQuote={this.getQuote}
          closeFn={() => {
            closeFn('result');
          }}
        />
      );
    }

    return (
      <Grid container style={{ flex: 1 }}>
        <WizardStep
          header={header}
          closeFn={() => {
            closeFn(currentStep + 1, totalStep);
          }}
          currentStep={currentStep}
          totalStep={totalStep}
          question={currentQuestion.question}
          answerA={currentQuestion.answers[0]}
          answerB={currentQuestion.answers[1]}
          onSelect={this.chooseAnswer}
        />
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Wizard);
