import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { enlargePercent } from '../../theme';

const styles = theme =>
  createStyles({
    ctaButton: {
      display: 'inline-block',
      padding: '10px 15px',
      margin: 10,
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '1.2rem',
      borderRadius: 10,
      backgroundColor: theme.palette.primary.main,
      border: '1px solid #181A38', //
      width: '100%',
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'none',
      cursor: 'pointer',
      transition: '0.4s linear',//
      '&:hover': {
        backgroundColor: 'white',//
        color: theme.palette.primary.main,//
      },
      [theme.breakpoints.up(400)]: {
        padding: '12px 25px 15px',
        fontSize: `${enlargePercent(1.3)}rem`,
      },
    },
  });

const Button = ({ answer, onSelect, classes, style = {} }) => (
  <button
    type="button"
    className={classes.ctaButton}
    style={style}
    onClick={() => onSelect(answer.value)}
  >
    {answer.text}
  </button>
);

export default withStyles(styles, { withTheme: true })(Button);
