import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import ensureGtag from '../../helpers/ensureGtag';

import CTAButton, { UiButton } from '../CTAButton/CTAButton';

import CloseButton from '../Quote/CloseButton';

import { pxToRem } from '../../theme';

const styles = theme =>
  createStyles({
    root: {
      flex: 1,
      flexWrap: 'nowrap',
      minHeight: '100vh',
      padding: '25px 20px',
      backgroundColor: '#e9e9e9',
      textAlign: 'center',
      [theme.breakpoints.up(400)]: {
        padding: '40px 25px',
      },
    },
    header: {
      color: '#181A38',
      marginBottom: 20,
      fontSize: pxToRem(36),
      lineHeight: 1,
      fontWeight: 600,
      [theme.breakpoints.up(400)]: {
        marginBottom: 30,
        fontSize: pxToRem(42),
      },
    },
    subHeader: {
      paddingBottom: 20,
      marginBottom: 10,
      fontSize: pxToRem(22),
      lineHeight: 1,
      fontWeight: 300,
      borderBottom: '1px solid #181A38',
      margin: '0 20px',
      [theme.breakpoints.up(400)]: {
        marginBottom: 20,
        paddingBottom: 25,
        fontSize: pxToRem(28),
      },
    },
    or: {},
    buttonWrap: {
      marginBottom: 20,
      flexWrap: 'nowrap',
      [theme.breakpoints.up(400)]: {
        marginBottom: 20,
      },
    },
    resultWrap: {
      textAlign: 'left',
      padding: '0 20px',
      marginBottom: 20,
      [theme.breakpoints.up(400)]: {
        // marginBottom: 40,
      },
    },
    yourResult: {
      marginBottom: 10,
      fontWeight: 600,
      [theme.breakpoints.up(400)]: {
        marginBottom: 15,
      },
    },
    resultCopy: {
      fontSize: pxToRem(18),
    },
    resultContentWrap: {
      maxWidth: 500,
      height: '100%',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignSelf: 'center',
      maxHeight: 800,
      [theme.breakpoints.up(800)]: {
        paddingTop: 40,
      },
    },
  });

const Result = ({
  classes,
  getQuote,
  closeFn,
  result,
  theme,
}) => (
  <Grid
    container
    direction="column"
    alignContent="center"
    className={classes.root}
  >
    <CloseButton iconProps={{ color: 'primary' }} closeFn={closeFn} />
    <Grid item container className={classes.resultContentWrap}>
      <Grid item>
        <Typography variant="h3" component="h3" className={classes.header}>
          Your result
        </Typography>
        <Typography variant="h5" component="h5" className={classes.subHeader}>
          Based on your answers, these are your best options.
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignContent="center"
        className={classes.buttonWrap}
      >
        <CTAButton
          ctaHref={result.types[0].href}
          ctaText={result.types[0].name}
          inverted
          btnStyles={{
            // backgroundColor: '#181A38',
          }}
        />
        <Typography variant="subtitle1" component="p" className={classes.or}>
          OR
        </Typography>
        <CTAButton
          ctaHref={result.types[1].href}
          ctaText={result.types[1].name}
          inverted
          btnStyles={{
            // backgroundColor: '#181A38',
          }}
        />
      </Grid>
      <Grid item className={classes.resultWrap}>
        <Typography
          variant="body1"
          component="p"
          className={classes.yourResult}
        >
          Your result:
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={classes.resultCopy}
        >
          <span dangerouslySetInnerHTML={{ __html: result.resultCopy }} />
        </Typography>
      </Grid>
      <Grid item container>
        <UiButton
          inverted
          size="large"
          btnType="wide"
          btnStyles={{
            // backgroundColor: '#1BB25F',
          }}
          onClick={getQuote}
        >
          Get Quote
        </UiButton>
      </Grid>
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(
  class WizardResult extends React.Component {
    componentDidMount() {
      ensureGtag('event', 'wizard_result_seen', {
        event_category: 'Wizard',
      });
    }

    render() {
      return <Result {...this.props} />;
    }
  }
);
